/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Router, useRouter } from 'next/router';
import Link from 'next/link';
import {
  createContext,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

const HistoryContext = createContext<Array<string>>([]);

export const HistoryProvider = ({ children }) => {
  const [history, setHistory] = useState<Array<string>>([]);
  const router = useRouter();

  useEffect(() => {
    setHistory([router.asPath]);

    const listener = (path) => setHistory((value) => [...value, path]);

    Router.events.on('routeChangeComplete', listener);

    router.beforePopState(() => {
      // pop 2 states, the current state and the previous state
      // once we're back on previous state as a result of popping
      // it will be added again in the `routeChangeComplete` event
      setHistory((value) => value.slice(0, -2));
      return true;
    });

    return () => {
      Router.events.off('routeChangeComplete', listener);
    };
  }, []);

  return (
    <HistoryContext.Provider value={history}>
      {children}
    </HistoryContext.Provider>
  );
};

interface BackLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  path: string;
}

const normalizePath = (path: string) =>
  path.replace(/^\//, '').replace(/\/$/, '');

export const BackLink = ({ children, path, ...props }: BackLinkProps) => {
  const router = useRouter();
  const history = useContext(HistoryContext);
  const previousPath = history[history.length - 2];

  // Naive implementation to determine if the paths are equal
  // This will break when used on routes that have query string/fragments
  // It is only used in a single place at the time of implementation
  const shouldUseNativeBackBehaviour =
    previousPath && normalizePath(previousPath) === normalizePath(path);

  return (
    <Link href={path}>
      <a
        onClick={
          shouldUseNativeBackBehaviour
            ? (e) => {
                e.preventDefault();
                router.back();
              }
            : null
        }
        {...props}
      >
        {children}
      </a>
    </Link>
  );
};
