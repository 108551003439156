import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import capitalize from '~/utils/string';
import Spinner from './spinner.component';

export interface ModalNavigationProps {
  submitting?: boolean;
  onSubmit?: () => void;
  submitLabel?: string;
  submittingWithNotification?: boolean;
  onSubmitWithNotification?: () => void;
  submitWithNotificationLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
  submitProps?: {
    type?: any;
    disabled?: boolean;
    className?: any;
  };
  cancelProps?: {
    type?: any;
    disabled?: boolean;
    className?: any;
  };
}

const ModalNavigation = ({
  onSubmit,
  onCancel,
  submitLabel,
  submitting,
  submittingWithNotification,
  onSubmitWithNotification,
  submitWithNotificationLabel,
  cancelProps,
  submitProps,
  cancelLabel,
}: ModalNavigationProps) => {
  const { t } = useTranslation();
  return (
    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      {onSubmitWithNotification && (
        <button
          type="submit"
          className="c-button--primary ml-2"
          onClick={onSubmitWithNotification}
          {...submitProps}
        >
          <div className="flex flex-row items-center">
            {submittingWithNotification && <Spinner className="text-white" />}
            {submitWithNotificationLabel || capitalize(t('opslaan'))}
          </div>
        </button>
      )}
      {onSubmit && (
        <button
          type="submit"
          className="c-button--primary"
          onClick={onSubmit}
          {...submitProps}
        >
          <div className="flex flex-row items-center">
            {submitting && <Spinner className="text-white" />}
            {submitLabel || capitalize(t('opslaan'))}
          </div>
        </button>
      )}
      <button
        type="button"
        onClick={onCancel}
        {...cancelProps}
        className={classNames('c-button--outline mr-2', cancelProps?.className)}
      >
        {cancelLabel || capitalize(t('annuleren'))}
      </button>
    </div>
  );
};

export default ModalNavigation;
