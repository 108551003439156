/* eslint-disable global-require */
module.exports = {
  content: ['./src/**/*.tsx', './packages/auth/**/*.tsx'],
  darkMode: false,
  theme: {
    extend: {
      container: {
        padding: '1rem',
        center: true,
      },
      backgroundImage: (theme) => ({
        header:
          "linear-gradient(135.14deg, rgba(143, 209, 106, 0.6) 16.97%, rgba(72, 174, 180, 0.75) 49.4%), url('/images/header.jpg')",
        about: "url('/images/about.png')",
        about_gradient:
          'linear-gradient(124.44deg, rgba(143, 209, 106, 0.6) -4.79%, rgba(72, 174, 180, 0) 126.4%)',
        demo_gradient:
          'linear-gradient(130deg, rgba(102,172,114,1) 0%, rgba(47,142,141,1) 47%);',
      }),
      minWidth: {
        4: '1rem',
        6: '1.5rem',
      },
      colors: {
        info: '#d1d1d6',
        success: '#8fd16a',
        danger: '#F7685B',
        warning: '#FFB946',

        main: '#244c5a',
        'main-100': '#BDE5F3',
        'main-200': '#A3CBD9',
        'main-300': '#8AB2C0',
        'main-400': '#7199A7',
        'main-500': '#7199A7',
        'main-600': '#577F8D',
        'main-700': '#3E6674',
        'main-800': '#244c5a',
        'main-900': '#0B3341',

        primary: '#8fd16a',
        'primary-100': '#F5FFD0',
        'primary-200': '#DCFFB7',
        'primary-300': '#C2FF9D',
        'primary-400': '#A9EB84',
        'primary-500': '#8FD16A',
        'primary-600': '#76B851',
        'primary-700': '#76B851',
        'primary-800': '#76B851',
        'primary-900': '#76B851',

        gray: {
          100: '#f9fafb',
          200: '#F3F5F7',
          300: '#9BA2B1',
          400: '#585F6B',
        },
        green: {
          200: '#eff6ee',
          300: '#8FD16A',
          400: '#599B9F',
          500: '#48AEB4',
          600: '#6899A7',
          700: '#003E50',
        },
      },
      fontFamily: {
        verdana: 'verdana',
        helvetica: 'helvetica Neue',
        roboto: 'Roboto',
      },
      fontSize: {
        10: 10,
        12: 12,
        14: 14,
        17: 17,
        18: 18,
        24: 24,
        28: 28,
        30: 30,
        40: 40,
      },
      gridTemplateColumns: {
        audit: '1fr 2fr 2fr 1fr',
      },
      width: {
        90: '90vw',
        1200: '1200px',
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['disabled', 'even'],
    },
  },

  plugins: [require('@tailwindcss/forms')],
};
