import { useEffect } from 'react';
import Router from 'next/router';
import { GTM_TRACKING_ID } from '../config';

interface ExtendedWindow extends Window {
  ga: any;
  gtag: (...args: Array<any>) => void;
}

declare var window: ExtendedWindow;

const gaSend = (...args) => {
  // When add-blocker is on getAll is not working
  if (!window.ga.getAll) {
    window.ga('send', ...args);
  } else {
    window.ga.getAll().forEach((tracker) => {
      window.ga(`${tracker.get('name')}.send`, ...args);
    });
  }
};

/**
 * Track a pageview through the gtag datalayer
 */
export const pageview = (url: string): void => {
  if (window.gtag) {
    window.gtag('config', GTM_TRACKING_ID, {
      page_path: url,
    });
  }
  if (window.ga) {
    gaSend({
      hitType: 'pageview',
      page: url,
      title: document.title,
    });
  }
};

/**
 *  Track an event through the gtag datalayer
 */
export const event = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: string;
  label: string;
  value: string;
}): void => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
  if (window.ga) {
    gaSend({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
};

/**
 * Insert this component into the Head tree of pages/_document
 */
export const GTMHeadScript = GTM_TRACKING_ID
  ? () => (
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){ dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', '${GTM_TRACKING_ID}');`,
          }}
        />
      </>
    )
  : () => null;

export const GTMBodyScript = GTM_TRACKING_ID
  ? () => (
      <noscript>
        <iframe
          title="gtag"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  : () => null;

export const GTMPageTracker = GTM_TRACKING_ID
  ? ({ children }) => {
      useEffect(() => {
        const listener = (url) => pageview(url);
        Router.events.on('routeChangeComplete', listener);

        return () => {
          Router.events.off('routeChangeComplete', listener);
        };
      }, []);

      return children;
    }
  : ({ children }) => children;
