import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import capitalize from '~/utils/string';
import Modal from './modal.component';

interface ConfirmModalProps {
  isOpen?: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  submitting?: boolean;
  submitError?: string;
}

const ConfirmModal = ({
  title,
  isOpen,
  onClose,
  description,
  confirmLabel,
  cancelLabel,
  onConfirm,
  submitting,
  submitError,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="max-w-screen-2xl"
      title={title || capitalize(t('bent u zeker?'))}
      modalNavigation={{
        submitting,
        onCancel: onClose,
        cancelLabel: cancelLabel || capitalize(t('annuleren')),
        onSubmit: onConfirm,
        submitLabel: confirmLabel || capitalize(t('verwijderen')),
        submitProps: {
          className: classNames(
            'bg-red-600 rounded-md px-4 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
            { 'opacity-50 pointer-events-none': submitting },
          ),
          disabled: submitting,
        },
        cancelProps: {
          disabled: submitting,
        },
      }}
    >
      {description ||
        capitalize(
          t('weet zeker dat je het aangeklikte item wilt verwijderen?'),
        )}
      {!!submitError && <span className="mt-3 c-error">{submitError}</span>}
    </Modal>
  );
};

export default ConfirmModal;
