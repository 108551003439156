import { ApolloProvider } from '@apollo/client';
import { ReactNode, useEffect, useState } from 'react';
import client, { registerErrorHandler } from '~/apollo-client';
import ApiErrorContext from '~/context/api-error.context';

const generateKey = () => Math.random().toString(16).substring(10);

export interface ApiError {
  key: string;
  error: Error & {
    extensions?: {
      exceptionCode: number;
    };
  };
}

const ApolloProviderWrapper = ({ children }: { children: ReactNode }) => {
  const [errors, setErrors] = useState<Array<ApiError>>([]);

  const handleApiError = (newErrors: Array<Error>) => {
    setErrors((value) =>
      [
        ...value,
        ...newErrors.map((error) => ({
          key: generateKey(),
          error,
        })),
      ].slice(-3),
    );
  };

  useEffect(() => {
    registerErrorHandler(handleApiError);
  }, [handleApiError]);

  return (
    <ApolloProvider client={client}>
      <ApiErrorContext.Provider
        value={{
          errors,
          handleErrorDismiss: (keyToRemove) =>
            setErrors((value) =>
              value.filter(({ key }) => key !== keyToRemove),
            ),
        }}
      >
        {children}
      </ApiErrorContext.Provider>
    </ApolloProvider>
  );
};

export default ApolloProviderWrapper;
