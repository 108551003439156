import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { createContext } from 'react';

export const STATE_INITIALIZING = 'initializing';
export const STATE_LOGGED_IN = 'logged-in';
export const STATE_LOGGED_OUT = 'logged-out';
export const STATE_FORCE_PASSWORD_CHANGE = 'force-password-change';
export const STATE_PASSWORD_CHANGE = 'password-change';
export const STATE_REQUEST_PASSWORD_RESET = 'request-password-reset';
export const STATE_CONFIRM_PASSWORD_RESET = 'confirm-password-reset';

export interface AuthenticationContextValue {
  state: string;
  isInitializing: boolean;
  user?: CognitoUser;
  userAttributes?: any;
  session?: CognitoUserSession;
  passwordResetInfo?: any;
  handleLogout?: () => Promise<void>;
  handlePasswordChange?: () => Promise<void>;
  handlePasswordResetRequest?: () => Promise<void>;
  handlePasswordResetRequested?: (values: any) => Promise<void>;
  handlePasswordHasBeenReset?: () => Promise<void>;
  handleForcePasswordChange?: (values: {
    user: CognitoUser;
    userAttributes: any;
  }) => Promise<void>;
  handleForcePasswordChanged?: (values: {
    session: CognitoUserSession;
  }) => Promise<void>;
  handlePasswordChanged?: () => Promise<void>;
  handleLoggedIn?: (values: {
    session: CognitoUserSession;
    user: CognitoUser;
    userAttributes: any;
  }) => Promise<void>;
}

const AuthenticationContext = createContext<AuthenticationContextValue>({
  state: STATE_INITIALIZING,
  isInitializing: true,
});

AuthenticationContext.displayName = 'Auth context';

export default AuthenticationContext;
