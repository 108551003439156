import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import {
  AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
  AWS_COGNITO_USER_POOL_ID,
} from '~/config';

export const AUTH_STATUS_NEW_PASSWORD_REQUIRED = 'new-password-required';
export const AUTH_LOGIN_REQUIRED = 'login-required';
export const AUTH_STATUS_OK = 'ok';
export const AUTH_STATUS_ERROR = 'error';

const USER_POOL_CLIENT_CONFIG = {
  UserPoolId: AWS_COGNITO_USER_POOL_ID,
  ClientId: AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(
  USER_POOL_CLIENT_CONFIG,
);

const createCognitoUser = ({ email }: { email: string }) =>
  new AmazonCognitoIdentity.CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

const createCredentials = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const credentials = {
    Username: email.toLowerCase(),
    Password: password,
  };

  return new AmazonCognitoIdentity.AuthenticationDetails(credentials);
};

const createErrorResponse = ({ error }: { error: string | Error }) => ({
  status: AUTH_STATUS_ERROR,
  error: error instanceof Error ? error.message : JSON.stringify(error),
  code: typeof error === 'object' && 'code' in error ? error.code : null,
});

interface BaseServiceResponse {
  status: string;
  error?: string;
}

export interface LoginResponse extends BaseServiceResponse {
  session?: AmazonCognitoIdentity.CognitoUserSession;
  user?: AmazonCognitoIdentity.CognitoUser;
  userAttributes?: any;
}

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  const authenticationDetails = createCredentials({ email, password });
  const cognitoUser = createCognitoUser({ email });

  return new Promise((resolve) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) =>
        resolve({
          status: AUTH_STATUS_OK,
          session,
          user: cognitoUser,
        }),
      onFailure: (error) => resolve(createErrorResponse({ error })),
      newPasswordRequired: (userAttributes) => {
        resolve({
          status: AUTH_STATUS_NEW_PASSWORD_REQUIRED,
          user: cognitoUser,
          userAttributes,
        });
      },
    });
  });
};

export interface RefreshAccessTokenResponse extends BaseServiceResponse {
  session?: AmazonCognitoIdentity.CognitoUserSession;
}

const refreshAccessToken = async ({
  user,
  session,
}: {
  user: AmazonCognitoIdentity.CognitoUser;
  session: AmazonCognitoIdentity.CognitoUserSession;
}): Promise<RefreshAccessTokenResponse> => {
  return new Promise((resolve) => {
    user.refreshSession(
      session.getRefreshToken(),
      (error, refreshedSession) => {
        if (error) {
          resolve(createErrorResponse({ error }));
        } else {
          resolve({
            status: AUTH_STATUS_OK,
            session: refreshedSession,
          });
        }
      },
    );
  });
};

export type LogoutResponse = BaseServiceResponse;

const logout = async ({
  user,
}: {
  user: AmazonCognitoIdentity.CognitoUser;
}): Promise<LogoutResponse> => {
  return new Promise((resolve) => {
    user.globalSignOut({
      onFailure: (error) => resolve(createErrorResponse({ error })),
      onSuccess: () =>
        resolve({
          status: AUTH_STATUS_OK,
        }),
    });
  });
};

export interface GetCurrentUserReponse extends BaseServiceResponse {
  user?: AmazonCognitoIdentity.CognitoUser | null;
  session?: AmazonCognitoIdentity.CognitoUserSession;
}

const getCurrentUser = async (): Promise<GetCurrentUserReponse> => {
  const user = userPool.getCurrentUser();

  if (user === null) {
    return {
      status: AUTH_STATUS_OK,
      user: null,
    };
  }

  return new Promise((resolve) => {
    user.getSession(
      async (
        error: Error | null,
        session: AmazonCognitoIdentity.CognitoUserSession,
      ) => {
        if (error?.name === 'NotAuthorizedException') {
          // current session is not valid, force logout to trigger a new login
          await user.signOut();
          return resolve({
            status: AUTH_LOGIN_REQUIRED,
          });
        }

        if (error) {
          return resolve(createErrorResponse({ error }));
        }

        return resolve({
          status: AUTH_STATUS_OK,
          session,
          user,
        });
      },
    );
  });
};

export interface CompleteAccountReponse extends BaseServiceResponse {
  session?: AmazonCognitoIdentity.CognitoUserSession;
}

const completeAccount = async ({
  user,
  userAttributes,
  newPassword,
}: {
  user: AmazonCognitoIdentity.CognitoUser;
  userAttributes: any;
  newPassword: string;
}): Promise<CompleteAccountReponse> => {
  const {
    email_verified: emailVerified,
    email,
    ...newAttributes
  } = userAttributes;

  return new Promise((resolve) => {
    user.completeNewPasswordChallenge(newPassword, newAttributes, {
      onSuccess: (session) =>
        resolve({
          status: AUTH_STATUS_OK,
          session,
        }),
      onFailure: (error) => resolve(createErrorResponse({ error })),
    });
  });
};

export type ChangePasswordResponse = BaseServiceResponse;

const changePassword = async ({
  user,
  oldPassword,
  newPassword,
}: {
  user: AmazonCognitoIdentity.CognitoUser;
  oldPassword: string;
  newPassword: string;
}): Promise<ChangePasswordResponse> => {
  return new Promise((resolve) => {
    user.changePassword(oldPassword, newPassword, (error) => {
      if (error) {
        return resolve(createErrorResponse({ error }));
      }

      return resolve({
        status: AUTH_STATUS_OK,
      });
    });
  });
};

export interface RequestPasswordResetResponse extends BaseServiceResponse {
  result?: any;
}

const requestPasswordReset = async ({
  email,
}: {
  email: string;
}): Promise<RequestPasswordResetResponse> => {
  const user = createCognitoUser({ email });

  return new Promise((resolve) => {
    user.forgotPassword({
      onSuccess: (result) => {
        resolve({
          status: AUTH_STATUS_OK,
          result,
        });
      },
      onFailure: (error) => resolve(createErrorResponse({ error })),
    });
  });
};

export type ConfirmPasswordResetResponse = BaseServiceResponse;

const confirmPasswordReset = async ({
  verificationCode,
  email,
  newPassword,
}: {
  verificationCode: string;
  email: string;
  newPassword: string;
}): Promise<ConfirmPasswordResetResponse> => {
  const user = createCognitoUser({ email });

  return new Promise((resolve) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () =>
        resolve({
          status: AUTH_STATUS_OK,
        }),
      onFailure: (error) => resolve(createErrorResponse({ error })),
    });
  });
};

export default {
  login,
  refreshAccessToken,
  getCurrentUser,
  logout,
  completeAccount,
  changePassword,
  requestPasswordReset,
  confirmPasswordReset,
};
