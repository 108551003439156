/* eslint-disable @typescript-eslint/no-unused-vars */
import getConfig from 'next/config';
import info from '@bitsoflove/console-info';

interface Process extends NodeJS.Process {
  browser: boolean;
}
declare let process: Process;

const { publicRuntimeConfig = {} } = getConfig() || {};

if (process.browser && publicRuntimeConfig.BUILD_INFO) {
  info(publicRuntimeConfig.BUILD_INFO);
}

export const {
  STATIC_DIR,
  BASE_URL,
  BRANCH,
  ENVIRONMENT,

  // Custom project environment variables
  GTM_TRACKING_ID,

  AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
  API_BASE_URL,
  API_URL,
  APP_URL,
} = publicRuntimeConfig;
