import { createContext } from 'react';
import { Company as GraphQLCompany, Theme } from '~/schema';

type Company = Partial<
  Omit<GraphQLCompany, 'themes'> & { themes: Array<Partial<Theme>> }
>;

export interface CompanyScopeContextProps {
  isInitializing: boolean;
  company?: Company;
  isPremium?: boolean;
  companies?: Array<Company>;
  handleCompanyChange?: (company: Partial<Company>) => void;
  setCompany?: (company: Company) => void;
}

const CompanyScopeContext = createContext<CompanyScopeContextProps>({
  isInitializing: true,
});

CompanyScopeContext.displayName = 'CompanyScopeContext';

export default CompanyScopeContext;
