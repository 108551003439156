import { createContext } from 'react';

interface Role {
  slug: string;
}

interface Tenant {
  id: string;
  name: string;
}

export interface Viewer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<Role>;
  tenant: Tenant;
}

export interface ViewerContextProps {
  isInitializing: boolean;
  viewer?: Viewer;
}

const ViewerContext = createContext<ViewerContextProps>({
  isInitializing: true,
});

ViewerContext.displayName = 'ViewerContext';

export default ViewerContext;
