import useAuth from '@auth/hooks/use-auth.hook';
import { ReactNode, useEffect, useState } from 'react';
import useCompanyContext from '~/hooks/company-scope.hook';
import useViewer from '~/hooks/viewer.hook';
import Logo from './common/logo.component';
import Spinner from './common/spinner.component';

const MINIMUM_INITIALIZATION_DURATION = 350;

const SplashScreen = ({ children }: { children: ReactNode }) => {
  const [
    hasMinimumInializationDurationExpired,
    setHasMinimumInitializationDurationExpired,
  ] = useState(false);
  const { isInitializing: isAuthInitializing } = useAuth();
  const { isInitializing: isViewerInitializing } = useViewer();
  const { isInitializing: isCompanyContextInitializing } = useCompanyContext();
  const isInitializing =
    isAuthInitializing || isCompanyContextInitializing || isViewerInitializing;

  useEffect(() => {
    const timoutId = setTimeout(() => {
      setHasMinimumInitializationDurationExpired(true);
    }, MINIMUM_INITIALIZATION_DURATION);

    return () => clearTimeout(timoutId);
  }, []);

  if (isInitializing || !hasMinimumInializationDurationExpired) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
          <Logo dark />
          <Spinner className="mt-6" />
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default SplashScreen;
