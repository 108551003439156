import { useContext } from 'react';
import CompanyScopeContext, {
  CompanyScopeContextProps,
} from '~/context/company-scope.context';

const useCompanyContext = (): CompanyScopeContextProps => {
  return useContext(CompanyScopeContext);
};

export default useCompanyContext;
