import { STATE_LOGGED_IN } from '@auth/context/authentication.context';
import useAuth from '@auth/hooks/use-auth.hook';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { ENVIRONMENT } from '~/config';
import { ROUTES } from '~/constants';
import CompanyScopeContext from '~/context/company-scope.context';
import { SubscriptionType, useGetViewerCompaniesQuery } from '~/schema';

const LOCAL_STORAGE_KEY = `inni-sure-tool_company-scope-${ENVIRONMENT}`;

const CompanyScopeProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { state, user } = useAuth();
  const [companyId, setCompanyId] = useLocalStorage(LOCAL_STORAGE_KEY, null);
  const { data, loading: isLoading, refetch } = useGetViewerCompaniesQuery({
    skip: state !== STATE_LOGGED_IN,
  });
  const companies = data?.viewerCompanies || [];

  const company = useMemo(() => {
    if (companies && companyId && data && !isLoading) {
      const activeCompany = companies.find(({ id }) => id === companyId);

      if (!activeCompany) {
        setCompanyId(null);
      }

      return activeCompany;
    }

    return null;
  }, [companyId, companies]);

  useEffect(() => {
    if (!companyId) {
      setCompanyId(companies?.[0]?.id);
    }
  }, [companyId, companies]);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user?.getUsername()]);

  const handleCompanyChange = (value) => {
    router.push(ROUTES.AUDITS);
    setCompanyId(value?.id);
  };

  const setCompany = (value) => {
    setCompanyId(value?.id);
  };

  return (
    <CompanyScopeContext.Provider
      value={{
        isInitializing: isLoading,
        company,
        isPremium: company?.subscription?.type === SubscriptionType.Premium,
        companies: user ? companies : [],
        handleCompanyChange,
        setCompany,
      }}
    >
      {children}
    </CompanyScopeContext.Provider>
  );
};

export default CompanyScopeProvider;
