import { Router } from 'next/router';
import { Component, ReactNode } from 'react';
import Unauthorized from '~/exceptions/unauthorized.exception';

interface AuthorizationBoundaryState {
  isUnauthorized: boolean;
}

class AuthorizationBoundary extends Component<
  { children: ReactNode },
  AuthorizationBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { isUnauthorized: false };
  }

  static getDerivedStateFromError(error: Error): AuthorizationBoundaryState {
    if (error instanceof Unauthorized) {
      return { isUnauthorized: true };
    }

    return { isUnauthorized: false };
  }

  componentDidMount(): void {
    Router.events.on('routeChangeComplete', this.resetStateOnRouteChange);
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.resetStateOnRouteChange);
  }

  resetStateOnRouteChange = (): void => {
    this.setState({
      isUnauthorized: false,
    });
  };

  render(): ReactNode {
    const { children } = this.props;
    const { isUnauthorized } = this.state;

    if (isUnauthorized) {
      return <>{null}</>;
    }

    return children;
  }
}

export default AuthorizationBoundary;
