import { useContext } from 'react';
import ConfirmModalContext, {
  ConfirmModalContextProps,
} from '~/context/confirm-modal.context';

const useConfirmModalContext = (): ConfirmModalContextProps => {
  return useContext(ConfirmModalContext);
};

export default useConfirmModalContext;
