import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nl from '~/translations/nl.translations.json';
import fr from '~/translations/fr.translations.json';

const resources = {
  nl,
  fr,
};

i18n.use(initReactI18next).init({
  defaultNS: 'translations',
  resources,
  lng: 'nl',
  fallbackLng: 'nl',

  interpolation: {
    escapeValue: false,
  },

  nsSeparator: false,
});

export default i18n;
