import Head from 'next/head';
import { NextSeo } from 'next-seo';
import AuthenticationProvider from '@auth/providers/authentication.provider';

import '~/../tailwind.config';
import '~/styles/globals.css';
import 'react-phone-input-2/lib/style.css';
import '~/styles/icon-font.css';
import { GTMPageTracker } from '~/utils/gtag';
import '~/i18n';

import { Page } from '~/types';
import CompanyScopeProvider from '~/providers/company-scope.provider';
import ViewerProvider from '~/providers/viewer.provider';
import ConfirmModalProvider from '~/providers/confirm-modal.provider';
import ConfirmModalContainer from '~/containers/confirm-modal.container';
import { setClientToken } from '~/apollo-client';
import SplashScreen from '~/components/splash-screen.component';
import AuthorizationBoundary from '~/containers/authorization-boundary.container';
import ApolloProviderWrapper from '~/providers/apollo-provider.provider';
import { HistoryProvider } from '~/utils/history-util';
import env from '../utils/env';

const MyApp = ({
  Component,
  pageProps,
}: {
  Component: Page;
  pageProps: any;
}) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <NextSeo
        title="INNI - Sure"
        noindex={!env('production')}
        nofollow={!env('production')}
      />
      <GTMPageTracker>
        <AuthenticationProvider
          onTokenChange={(token) => setClientToken(token)}
          currentPage={{
            isPublic: Component?.isPublic,
          }}
        >
          <ApolloProviderWrapper>
            <ViewerProvider>
              <CompanyScopeProvider>
                <SplashScreen>
                  <AuthorizationBoundary>
                    <HistoryProvider>
                      <ConfirmModalProvider>
                        <Component {...pageProps} />
                        <ConfirmModalContainer />
                      </ConfirmModalProvider>
                    </HistoryProvider>
                  </AuthorizationBoundary>
                </SplashScreen>
              </CompanyScopeProvider>
            </ViewerProvider>
          </ApolloProviderWrapper>
        </AuthenticationProvider>
      </GTMPageTracker>
    </>
  );
};

export default MyApp;
