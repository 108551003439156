import { createContext } from 'react';

export interface ConfirmModalResponse {
  error?: string;
}
export interface ConfirmModalData {
  title?: string;
  description?: string;
  callbackFunc?: () => Promise<ConfirmModalResponse> | ConfirmModalResponse;
  confirmLabel?: string;
  cancelLabel?: string;
}

export interface ConfirmModalContextProps {
  confirmModalData?: ConfirmModalData;
  setConfirmModalData?: (data: ConfirmModalData) => void;
  submitting?: boolean;
  setSubmitting?: (submitting: boolean) => void;
  submitError?: string;
  setSubmitError?: (submitError: string) => void;
}

const ConfirmModalContext = createContext<ConfirmModalContextProps>({});

ConfirmModalContext.displayName = 'ConfirmModalContext';

export default ConfirmModalContext;
