import { useEffect, useState } from 'react';
import ConfirmModal from '~/components/common/confirm-modal.component';
import useConfirmModalContext from '~/hooks/confirm-modal.hook';

const ConfirmModalContainer = () => {
  const {
    confirmModalData,
    submitting,
    setSubmitting,
    submitError,
    setSubmitError,
  } = useConfirmModalContext();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const resetFormState = () => {
    setSubmitting(false);
    setSubmitError(null);
  };

  const handleConfirm = async (): Promise<void> => {
    setSubmitting(true);
    setSubmitError(null);
    const { error } = (await confirmModalData?.callbackFunc?.()) || {};
    if (!error) {
      resetFormState();
      handleClose();
    } else {
      setSubmitting(false);
      setSubmitError(error);
    }
  };

  useEffect(() => {
    if (confirmModalData) {
      resetFormState();
      setIsOpen(true);
    }
  }, [confirmModalData, setIsOpen]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      submitting={submitting}
      submitError={submitError}
      {...confirmModalData}
    />
  );
};

export default ConfirmModalContainer;
