import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NextLink,
  Operation,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { API_URL } from './config';

let token = null;

export function setClientToken(newToken?: string): void {
  token = newToken;
}

const httpLink = createHttpLink({
  uri: API_URL,
});

const batchLink = new BatchHttpLink({
  batchMax: 5,
  batchInterval: 200,
  uri: API_URL,
});

const fetchLink = process.env.NODE_ENV !== 'production' ? httpLink : batchLink;

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

let errorHandler = null;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (errorHandler) {
    if (graphQLErrors) {
      errorHandler(graphQLErrors);
    }

    if (networkError) {
      errorHandler([networkError]);
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(fetchLink)]),
  cache: new InMemoryCache(),
});

export const registerErrorHandler = (
  handleError: (errors: Array<Error>) => void,
): void => {
  errorHandler = handleError;
};

export default client;
