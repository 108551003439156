import { ReactNode, useCallback, useEffect, useState } from 'react';
import authService, {
  AUTH_LOGIN_REQUIRED,
  AUTH_STATUS_ERROR,
  AUTH_STATUS_OK,
} from '@auth/services/authentication.service';

import AuthenticationContext, {
  STATE_INITIALIZING,
  STATE_LOGGED_IN,
  STATE_LOGGED_OUT,
} from '@auth/context/authentication.context';

import { useRouter } from 'next/router';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ROUTES } from '~/constants';

interface CurrentPage {
  isPublic: boolean;
}

interface AuthenticationProviderProps {
  currentPage: CurrentPage;
  onTokenChange: (token?: string) => void;
  children: ReactNode;
}

const DEFAULT_ROUTE = '/';

const REFRESH_CHECK_INTERVAL = 5 * 60 * 1000;
const REFRESH_BUFFER = 15 * 60 * 1000;

const AuthenticationProvider = ({
  currentPage,
  onTokenChange,
  children,
}: AuthenticationProviderProps) => {
  const router = useRouter();
  const [state, setState] = useState<string>(STATE_INITIALIZING);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [userAttributes, setUserAttributes] = useState<any | null>(null);
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [passwordResetInfo, setPasswordResetInfo] = useState<{
    [key: string]: any;
  } | null>(null);

  const handleSessionChange = (newSession?: CognitoUserSession) => {
    onTokenChange(newSession?.getAccessToken()?.getJwtToken());
    setSession(newSession);
  };

  const handleLogout = useCallback(async () => {
    if (user) {
      await authService.logout({ user });
    }

    setUser(null);
    setUserAttributes(null);
    handleSessionChange(null);
    setState(STATE_LOGGED_OUT);
    router.push('/login');
  }, [user]);

  const handlePasswordChange = useCallback(async () => {
    router.push('/change-password');
  }, []);

  const handlePasswordResetRequest = useCallback(async () => {
    router.push('/request-password-reset');
  }, []);

  const handlePasswordResetRequested = useCallback(async (values) => {
    setPasswordResetInfo(values);
    router.push('/confirm-password-reset');
  }, []);

  const handlePasswordHasBeenReset = useCallback(async () => {
    setPasswordResetInfo(null);
    router.push('/login');
  }, []);

  const handleForcePasswordChange = useCallback(async (values) => {
    setUser(values.user);
    setUserAttributes(values.userAttributes);
    router.push('/forced-password-change');
  }, []);

  const handleForcePasswordChanged = useCallback(async (values) => {
    handleSessionChange(values.session);
    setState(STATE_LOGGED_IN);
    router.push(DEFAULT_ROUTE);
  }, []);

  const handleLoggedIn = useCallback(async (values) => {
    handleSessionChange(values.session);
    setUser(values.user);
    setUserAttributes(values.userAttributes);
    setState(STATE_LOGGED_IN);
    router.push(DEFAULT_ROUTE);
  }, []);

  const handlePasswordChanged = useCallback(async () => {
    router.push(DEFAULT_ROUTE);
  }, []);

  useEffect(() => {
    if (state === STATE_INITIALIZING) {
      authService.getCurrentUser().then(({ status, ...response }) => {
        if (status === AUTH_STATUS_OK && response.session) {
          handleSessionChange(response.session);
          setUser(response.user || null);
          setState(STATE_LOGGED_IN);
        } else if (status === AUTH_STATUS_OK) {
          setState(STATE_LOGGED_OUT);
        } else if (status === AUTH_LOGIN_REQUIRED) {
          setState(STATE_LOGGED_OUT);
        } else if (status === AUTH_STATUS_ERROR) {
          setError(response.error || null);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!currentPage.isPublic && state === STATE_LOGGED_OUT) {
      router.push(ROUTES.LOGIN);
    }

    if (router.route === ROUTES.HOME && state === STATE_LOGGED_IN) {
      router.push(ROUTES.AUDITS);
    }
  }, [currentPage, state]);

  useEffect(() => {
    if (session && user) {
      const timeoutId = setInterval(async () => {
        const timeUntilExpiration =
          session.getAccessToken().getExpiration() * 1000 - Date.now();

        if (timeUntilExpiration < REFRESH_BUFFER) {
          const {
            error: refreshError,
            session: refreshedSession,
          } = await authService.refreshAccessToken({
            user,
            session,
          });

          if (refreshError) {
            setError(refreshError);
          } else {
            handleSessionChange(refreshedSession);
          }
        }
      }, REFRESH_CHECK_INTERVAL);

      return () => clearTimeout(timeoutId);
    }

    return () => null;
  }, [session, user]);

  const shouldShowPage =
    currentPage.isPublic ||
    (!currentPage.isPublic && state === STATE_LOGGED_IN);

  return (
    <AuthenticationContext.Provider
      value={{
        state,
        isInitializing: state === STATE_INITIALIZING,
        ...(user ? { user } : {}),
        userAttributes,
        ...(session ? { session } : {}),
        passwordResetInfo,
        handleLogout,
        handlePasswordChange,
        handlePasswordResetRequest,
        handlePasswordResetRequested,
        handlePasswordHasBeenReset,
        handleForcePasswordChange,
        handleForcePasswordChanged,
        handlePasswordChanged,
        handleLoggedIn,
      }}
    >
      {error}
      {shouldShowPage && children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
