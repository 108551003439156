import { ReactNode, useState } from 'react';
import ConfirmModalContext, {
  ConfirmModalData,
} from '~/context/confirm-modal.context';

const ConfirmModalProvider = ({ children }: { children: ReactNode }) => {
  const [confirmModalData, setConfirmModalData] = useState<ConfirmModalData>(
    null,
  );

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>(null);

  return (
    <ConfirmModalContext.Provider
      value={{
        confirmModalData,
        setConfirmModalData,
        submitting,
        setSubmitting,
        submitError,
        setSubmitError,
      }}
    >
      {children}
    </ConfirmModalContext.Provider>
  );
};

export default ConfirmModalProvider;
