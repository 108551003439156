import { STATE_LOGGED_IN } from '@auth/context/authentication.context';
import useAuth from '@auth/hooks/use-auth.hook';
import { ReactNode, useEffect } from 'react';
import ViewerContext from '~/context/viewer.context';
import { useGetViewerQuery } from '~/schema';

const ViewerProvider = ({ children }: { children: ReactNode }) => {
  const { state, user } = useAuth();
  const { data, loading: isLoading, refetch } = useGetViewerQuery({
    skip: state !== STATE_LOGGED_IN,
    fetchPolicy: 'no-cache',
  });

  const viewer = data?.viewer;
  const userName = user?.getUsername();

  useEffect(() => {
    if (!isLoading && userName) {
      refetch();
    }
  }, [userName]);

  return (
    <ViewerContext.Provider
      value={{
        isInitializing: isLoading,
        viewer: !viewer
          ? null
          : {
              id: viewer.id,
              email: viewer.email,
              firstName: viewer.firstName,
              lastName: viewer.lastName,
              roles: viewer.roles.map(({ slug }) => ({
                slug,
              })),
              tenant: viewer.tenant,
            },
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
};

export default ViewerProvider;
