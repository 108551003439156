import { useContext } from 'react';
import AuthenticationContext, {
  AuthenticationContextValue,
} from '@auth/context/authentication.context';

const useAuth = (): AuthenticationContextValue => {
  return useContext(AuthenticationContext);
};

export default useAuth;
