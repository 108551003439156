import { Fragment, ReactNode, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import capitalize from '~/utils/string';
import ModalNavigation, {
  ModalNavigationProps,
} from './modal-navigation.component';

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  modalNavigation?: ModalNavigationProps;
  className?: string;
  children: ReactNode;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  modalNavigation,
  className,
}: ModalProps) => {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={onClose}
      >
        <div
          ref={cancelButtonRef}
          className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-80 backdrop-filter bg-gray-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                className,
                'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6',
              )}
            >
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-semibold text-gray-900"
              >
                {capitalize(title || '')}
              </Dialog.Title>
              <div className="mt-2">{children}</div>
              {modalNavigation && (
                <div className="mt-4">
                  <ModalNavigation {...modalNavigation} />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
