import classNames from 'classnames';

interface LogoProps {
  className?: string;
  dark?: boolean;
}

const Logo = ({ className, dark }: LogoProps) => {
  return (
    <img
      src={dark ? '/images/header-logo-dark.png' : '/images/logo.png'}
      alt="INNIsure logo"
      className={classNames(
        'mx-auto h-12 w-auto fill-current text-main-900',
        className,
      )}
    />
  );
};

export default Logo;
