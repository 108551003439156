import { createContext } from 'react';
import { ApiError } from '~/providers/apollo-provider.provider';

export interface ApiErrorContextProps {
  errors: Array<ApiError>;
  handleErrorDismiss?: (key: string) => void;
}

const ApiErrorContext = createContext<ApiErrorContextProps>({
  errors: [],
});

ApiErrorContext.displayName = 'ApiErrorContext';

export default ApiErrorContext;
