import { NextPage } from 'next';

export type Page<PageProps = {}> = NextPage<PageProps> & {
  isPublic: boolean;
};

export enum State {
  Info,
  Warning,
  Danger,
  Success,
}

export interface Theme {
  id: string;
  name: string;
}

export interface Site {
  id: string;
  name: string;
}

export interface Aggregate {
  status: {
    name: string;
  };
  count: number;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Audit {
  id: string;
  name: string;
  year: string;
  quarter: string;
  sites: Array<Site>;
  themes: Array<Theme>;
  auditor: User;

  requirementAggregates: Array<Aggregate>;
  actionAggregates: Array<Aggregate>;
}

export type ResourceData = {
  description?: any;
  content?: any;
  title?: any;
};

export interface Asset {
  id: string;
  mimeType: string;
  size: number;
  fileName: string; // original filename
}
